<script setup lang="ts">
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiRatingRadialProps {
  circleClass?: string;
  fontClass?: string;
  formatter?: string;
  rating?: number | null;
  showLabel?: boolean;
  smaller?: boolean;
  useColors?: boolean;
}
const props = withDefaults(defineProps<CiRatingRadialProps>(), {
  circleClass: 'circle--primary',
  fontClass: 'font-normal',
  formatter: '{value}',
  rating: null,
  showLabel: true,
  smaller: false,
  useColors: false,
});

/********************
 * REFS & VARS       *
 ********************/
const circleWrapperClasses = computed(() => {
  const baseClasses = props.smaller ? ['smaller'] : [];

  if (props.useColors) {
    if (props.rating && props.rating < 2) {
      return [...baseClasses, 'red'];
    }
    if (props.rating && props.rating < 4) {
      return [...baseClasses, 'yellow'];
    }
    return [...baseClasses, 'green'];
  }

  return baseClasses;
});

const label = computed(() => {
  const params = { value: props.rating ? props.rating.toFixed(1) : '—' };
  return props.formatter.replace(/\{([^}]+)\}/g, (_, b) => params[b]);
});
</script>

<template>
  <div class="rating-radial">
    <div
      class="rating-radial__circular relative block size-full rounded-full bg-white"
      :class="circleWrapperClasses"
    >
      <svg
        class="circular-chart"
        viewBox="0 0 36 36"
      >
        <path
          class="circle-bg"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke="#444"
          stroke-width="2.8"
        />
        <path
          v-show="props.rating"
          :class="[props.useColors ? '' : `${props.circleClass}`]"
          class="circle"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke="#444"
          stroke-width="1"
          :stroke-dasharray="`${(props.rating / 5) * 100}, 100`"
        />
      </svg>
      <div
        v-if="props.showLabel"
        class="absolute left-0 top-0 flex size-full items-center justify-center"
      >
        <span
          :class="props.fontClass"
          class="circular-text mb-0 text-black"
          v-html="label"
        />
      </div>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<style lang="postcss">
.chart-wrapper .rating-radial__circular {
  @apply border-[0.313rem] border-white;
}

.chart-wrapper .rating-radial__circular.smaller {
  border-width: 0.2rem;
}

.chart-wrapper.chart-wrapper--border-lg .rating-radial__circular {
  border-width: 0.75rem;
}

.chart-wrapper.chart-wrapper--border-none .rating-radial__circular {
  @apply border-0;
}

.rating-radial .rating-radial__circular.green .circular-chart .circle {
  @apply stroke-primary;
}

.rating-radial .rating-radial__circular.red .circular-chart .circle {
  @apply stroke-danger;
}

.rating-radial .rating-radial__circular.yellow .circular-chart .circle {
  @apply stroke-yellow;
}

.rating-radial .rating-radial__circular .circular-chart .circle {
  @apply fill-none stroke-info;
  stroke-dasharray: '0, 100';
  stroke-linecap: round;
  stroke-width: 2.8;
}

.rating-radial .rating-radial__circular .circular-chart .circle.circle--primary {
  @apply stroke-primary;
}

.rating-radial .rating-radial__circular .circular-chart .circle.circle--gray {
  @apply stroke-gray-30;
}

.rating-radial .rating-radial__circular .circular-chart .circle-bg {
  @apply stroke-[#6d767e26];
  stroke-width: 2.8;
}
</style>
